// import router
import { withRouter } from "react-router-dom";
// import js components
import Header from "../Header";
import BannerSection from "../BannerSection";
import FooterBanner from "../FooterBanner";
import SupportOne from "../SupportOne";
import PlatForm from "../PlatForm";
import CTASection from "../CTASection";
import Testimonial from "../Testimonial";
import Cards from "../Cards";
import BrandPanel from "../BrandPanel";
import Footer from "../Footer";


function Homepage(props) {

    //CTA 
const cta = {
    heading: props.vars.s8_heading_1,
    description: props.vars.s8_description,
    btn_name: props.vars.s8_btn_name,
}

    return (
        <>
            <Header />

            <BannerSection />

            <FooterBanner />

            <SupportOne />

            <PlatForm />

            <CTASection />

            <Testimonial />

            <Cards />

            <BrandPanel vars={cta} />

            <Footer />
        </>
    )
}
export default withRouter(Homepage);