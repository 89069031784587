// import js components
import Header from "../Header";
import BrandPanel from "../BrandPanel";
import Footer from "../Footer";


export default function SkeletonPage() {
    return (
        <>
            <Header />

            <BrandPanel />

            <Footer />
        </>
    )
}