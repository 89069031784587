import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, ViewBoardsIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
// import * as vars from "../Variables/GitVersionControlVariables";


export default function SignIn(props) {
  return (
    <div className="relative overflow-hidden bg-gray-800">
      <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
        <svg className="absolute bottom-0 right-0 mb-48 text-gray-700 transform translate-x-1/2 lg:top-0 lg:mt-4 lg:mb-0 xl:transform-none xl:translate-x-0" width={364} height={384} viewBox="0 0 364 384" fill="none">
          <defs>
            <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
        </svg>
      </div>
      <Popover className="relative pb-16 sm:pb-24">
        {({ open }) => (
          <>

            <main className="mt-16 sm:mt-24">
              <div className="mx-auto max-w-7xl">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl sm:text-center md:max-w-2xl md:mx-auto lg:col-span-8 lg:text-left lg:flex lg:items-center">
                    <div>
                      {/* <a
                        href="#"
                        className="inline-flex items-center p-1 pr-2 text-white bg-gray-900 rounded-full sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                      >
                        <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                          {props.vars.s1_title_1}
                        </span>
                        <span className="ml-4 text-sm">{props.vars.s1_title_2}</span>
                        <ChevronRightIcon className="w-5 h-5 ml-2 text-gray-500" aria-hidden="true" />
                      </a> */}
                      <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                        <span className="md:block">{props.vars.heading}</span>{' '}
                        <span className="mt-5 text-blue-500 md:block">{props.vars.headinghighlight}</span>
                      </h1>
                      <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        {
                          props.vars.description
                        }
                      </p>
                      <p className="mt-8 text-sm font-semibold tracking-wide text-white uppercase sm:mt-10">{props.vars.s1_sub_heading}</p>
                      <div className="w-full mt-5 sm:mx-auto sm:max-w-lg lg:ml-0">
                        <div className="flex flex-wrap items-start justify-between">
                          <div className="flex justify-center px-1">
                            <img
                              className="h-9 sm:h-10"
                              src={props.vars.img1}
                              alt={props.vars.alt1}
                            />
                          </div>
                          <div className="flex justify-center px-1">
                            <img
                              className="h-9 sm:h-10"
                              src={props.vars.img2}
                              alt={props.vars.alt2}
                            />
                          </div>
                          <div className="flex justify-center px-1">
                            <img
                              className="h-9 sm:h-10"
                              src={props.vars.img3}
                              alt={props.vars.alt3}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-md mx-auto mt-16 sm:max-w-3xl sm:px-6 lg:max-w-7xl sm:mt-24 lg:mt-0 lg:col-span-4">
                    <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                    <div className="px-4 py-6 border-b-2 border-gray-200 bg-gray-50 sm:px-10">
                        <p className="text-2xl font-bold text-center text-gray-500 leading-12">{props.vars.card_title}</p>
                      </div>
                      <div className="px-4 pb-8 sm:px-10">
                      <div class="flex sm:mx-auto">
                      <div class="p-2 w-full">
                      <div class="flex-wrap p-4 h-full content-center">
                        {props.vars1.map((feature) => (
                          <div className="flex p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span class="ml-1 title-font font-medium">{feature.list}</span>
                        </div>
                        ))} 
                      </div>
                      <div>
                          <button type="submit" className="flex justify-center w-full px-4 py-2 -mt-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            GET STARTED
                          </button>
                      </div>
                    </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </Popover>
    </div>
  )
}
