// import router
import { withRouter } from "react-router-dom";
// import js components
import Header from "../Header";
import ChatScreen from "../ChatScreen";
import HeaderSection from "../HeaderSection";

function WhyVegaOps(props) {
  const header_section_var_2 = { "heading": props.vars.s8_heading, "description": props.vars.s8_subheading }


return (
  <>
  <Header />
  <HeaderSection vars={header_section_var_2} />
  <ChatScreen />
</>
)
}
export default withRouter(WhyVegaOps);