// import js components
import Header from "../Header";
import ContactForm from "../ContactForm";
import ClientLogos from "../ClientLogos";

import Footer from "../Footer";
import ContactHeading from "../ContactHeading";

export default function ContactPage() {
    return (
        <>
            <Header />
            
            <ContactHeading />

            <ContactForm />

            <ClientLogos />

            <Footer />
        </>
    )
}