/////////////////
// SECTION 01 //
///////////////

export const s1_heading = 'Choose the Plan that Suits your Business';
export const s1_description = "Supercharge your automation game with VegaOps.";

/////////////////
// SECTION 02 //
///////////////

export const s2_heading = 'Simple no-tricks pricing';
export const s2_description = "If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.";
export const s2_input_label = "How many users do you have?";
export const s2_card_heading = 'Lifetime Membership';
export const s2_card_description = 'Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.';
export const s2_card_sub_heading = "What's included";
export const s2_card_description_1 = 'Private forum access';
export const s2_card_description_2 = "Member resources";
export const s2_card_description_3 = "Entry to annual conference";
export const s2_card_description_4 = "Official member t-shirt";
export const s2_card_2_heading = "Pay once, own it forever";
export const s2_card_2_price = "$349";
export const s2_card_2_currency = "USD";
export const s2_card_2_href = "Learn about our membership policy";
export const s2_card_2_btn_name = "Get Access";
export const s2_card_2_description = "Get a free sample";
export const s2_card_2_description_highlight = "(20MB)";


/////////////////
// SECTION 03 //
///////////////
export const heading = "Our Recommended Services";
export const description = "Have a specific requirement not included in the plans? We have got you covered.";
export const s3_title_1 = "Cloud Migration";
export const s3_description_1 = <p>Cloud is the <b> new normal </b> for enterprise IT. Move to the cloud seamlessly with our expertise in Cloud Migration Services.</p>;
export const s3_title_2 = "SVN to GIT Migration";
export const s3_description_2 = <p>Still stuck with SVN for version control? Well, Git opens the <b> door to DevOps </b> and with VegaOps it's a smooth ride.</p>;
export const s3_title_3 = "Release Management";
export const s3_description_3 = <p>Achieve coordinated, <b>process driven organization </b> with our mature release management solutions. Automate manual deployment tasks to gain efficiency.</p>;


/////////////////
// SECTION 04 //
///////////////

export const s4_heading = "Frequently asked questions";
export const s4_sub_heading_1 = "What's the best thing about Switzerland?";
export const s4_sub_description_1 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";
export const s4_sub_heading_2 = "What's the best thing about Switzerland?";
export const s4_sub_description_2 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";
export const s4_sub_heading_3 = "What's the best thing about Switzerland?";
export const s4_sub_description_3 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";
export const s4_sub_heading_4 = "What's the best thing about Switzerland?";
export const s4_sub_description_4 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";
export const s4_sub_heading_5 = "What's the best thing about Switzerland?";
export const s4_sub_description_5 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";
export const s4_sub_heading_6 = "What's the best thing about Switzerland?";
export const s4_sub_description_6 = "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.";


