/////////////////
// SECTION 01 //
///////////////

// export const s1_title_1 = "We're hiring";
// export const s1_title_2 = 'Visit our careers page';
export const s1_heading = 'Powerful Git for';
export const s1_heading_highlight = 'Professional Teams';
export const s1_description= `Fully private enterprise Git hosting at a fraction of the cost, coupled with built-in CI/CD Automation and 24/7 Support.`;
export const s1_sub_heading = "Used by";
export const s1_img_1 = "https://tailwindui.com/img/logos/tuple-logo-gray-400.svg";
export const s1_img_1_alt = "Tuple";
export const s1_img_2 = "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg";
export const s1_img_2_alt = "Workcation";
export const s1_img_3 = "https://tailwindui.com/img/logos/statickit-logo-gray-400.svg";
export const s1_img_3_alt = "StaticKit";
export const s1_heading_2 = 'Distributed is the';
export const s1_heading_highlight_2 = 'new Centralized';
export const s1_description_2 = 'Fully private enterprise Git hosting at a fraction of the cost, coupled with built-in CI/CD Automation and 24/7 Support.';
export const card_title = 'Managed, Enterprise Git Hosting';
export const s1_list_1 = 'Git Flow Design';
export const s1_list_2 = 'Code Review';
export const s1_list_3 = 'Issue Tracking';
export const s1_list_4 = 'Code Security Analysis';
export const s1_list_5 = 'Access and Workflows';
export const s1_list_6 = '24/7 DevOps Support';

/////////////////
// SECTION 02 //
///////////////

export const s2_heading = "Remote-ready Git Source Code Management";
export const s2_description = "Simple and Secure Git Hosting for all your Software Projects";
export const s2_card_1_heading = "Unlimited Projects";
export const s2_card_1_description = `Effectively manage unlimited public, internal or private projects on a central Git server dedicated to your software projects.`;
export const s2_card_2_heading = "Team Collaboration";
export const s2_card_2_description = `Implement organizational workflows, work with team members on files real-time, review, and contribute code together.`;
export const s2_card_3_heading = "Scale and Flexibility";
export const s2_card_3_description = `Efficiently handle both small-scale and large-scale projects. Record and track every activity of your team members.`;
export const s2_card_4_heading = "Code Review and Quality";
export const s2_card_4_description = `Excellent code depends on rigorous review. Design your Review workflows, improve code delivery speed and quality.`;
export const s2_card_5_heading = "Contribution Analytics";
export const s2_card_5_description = `Analyze your team's contributions over some time, identify opportunities for improvement with team members.`;
export const s2_card_6_heading = "Built-in Security";
export const s2_card_6_description = `Git is designed to maintain the integrity of your source code using cryptographic encryption algorithms.`;

/////////////////
// SECTION 03 //
///////////////

export const s3_heading = "Improve Code Quality with Code Review";
export const s3_description = `Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
viverra dui tellus ornare pharetra.`;
export const s3_heading_1 = "Git Flow";
export const s3_subheading_1 = "Well suited for collaboration and scaling the development teams working on multiple releases in parallel.";
export const s3_name_1 = "Gitflow Branches";
export const s3_list_1 = "Master and Develop are the two main branches, along with a few supporting branches like Feature, Release and Hotfix.";
export const s3_name_2 = "Isolate Development from Finished Work";
export const s3_list_2 = "Development is done in the feature branch and is only merged with the main code when the developer is confident about code release.";
export const s3_name_3 = "Scheduled Release Cycle";
export const s3_list_3 = "Gitflow comes handy when you are dealing with several versions in the production and for projects that have a scheduled release cycle.";
export const s3_heading_2 = "GitHub Flow";
export const s3_subheading_2 = "Helpful for teams that deploy all the time and have no scheduled releases. ";
export const s3_name_4 = "Code in Master Branch = Deployable";
export const s3_list_4 = "This approach is simply based on an assumption that the code in master branch is bug free and deployable at any point in time.";
export const s3_name_5 = "New branch for New Features";
export const s3_list_5 = "Everytime you need to work on something new, create a new branch off Master and after completion/review merge it into Master.";
export const s3_name_6 = "Issue Handling";
export const s3_list_6 = "In case there are any issues, commits will be reverted or new commits will be introduced fixing the issue.";
export const s3_heading_3 = "GitLab Flow";
export const s3_subheading_3 = "Useful for teams who can't control the timing of the release or teams having fixed deployment windows but still merge code at other times.";
export const s3_name_7 = "Issue Tracking";
export const s3_list_7 = "It supports feature driven development and feature branches with issue tracking.";
export const s3_name_8 = "Analyze Code Quality";
export const s3_list_8 = "It has a pre-production branch to make bug fixes before merging changes back to main branch and sending them to production.";
export const s3_name_9 = "Support Different Environments";
export const s3_list_9 = "With GitLab Flow, developers can collaborate on and maintain several versions of software in different environments like staging and production.";




/////////////////
// SECTION 04 //
///////////////

export const s4_heading = "Powerful Git Features";
export const s4_description = `Developed and Designed for Developers`;
export const s4_title_1 = "Activity Stream";
export const s4_description_1 = "View/Track a list of all the project activities like commits, issues, merges, comments by team members.";
export const s4_title_2 = "Commit Graphs";
export const s4_description_2 = "Analyze project's commit history and code contributions by your project members on different branches.";
export const s4_title_3 = "Rollback Commits";
export const s4_description_3 = "Easily roll back any of your commits or a single merge request on PWSLab with a click of a button.";
export const s4_title_4 = "Secure Signed commits";
export const s4_description_4 = "Identify the developer's contributions to prove that a commit was performed by a certain user using GPG signed commits.";
export const s4_title_5 = "Powerful Security";
export const s4_description_5 = "Git employs SHA1 to store all records in Hash which allow each item to collaborate with one another.";
export const s4_title_6 = "Project Discussions";
export const s4_description_6 = "Project members can log code commits, discuss issues, share media files, and code snippets in the issue boards.";
export const s4_title_7 = "Work Simultaneously ";
export const s4_description_7 = "Users can work on same project simultaneously without interfering with other developer's code.";
export const s4_title_8 = "Built-in Continuous Delivery";
export const s4_description_8 = "Build, test, and deploy with in-built CI/CD. Benefit from customizable CI/CD configuration and fast feedback loops.";
export const s4_title_9 = "Protected Branches";
export const s4_description_9 = "Secure code contributions to sensitive production branches with merge request approvals from senior project members.";
export const s4_title_10 = "Repository Mirroring";
export const s4_description_10 = "Automatically schedule mirroring for your projects to alternative Git Hosting services for Backup or CI/CD purposes.";
export const s4_title_11 = "Import Projects";
export const s4_description_11 = "Import your projects from GitHub, BitBucket, GitLab, Gitea, Google Code, or any other VCS in just a few clicks.";
export const s4_title_12 = "Export Projects";
export const s4_description_12 = "Export your project's entire commits and related data from PWSLab to any other Git Hosting services easily.";


/////////////////
//CTA///////////
///////////////

export const heading = "Continue for FREE Forever";
export const description = "Upgrade when ready";
export const btn_name = "Call us Now!";

/////////////////
// SECTION 05 //
///////////////

export const s5_heading = "Use a single DevOps solution to manage entire Software Development Lifecycle";
export const s5_title_1 = "Mobile notifications";
export const s5_description_1 = "From Software Development till Customer Deployment and Delivery, we ensure every aspect is offered in a single interface, at a highly competitive and affordable pricing.";
export const s5_title_2 = "Reminder emails";
export const s5_description_2 = "Unlike other DIY solutions, our Expert DevOps Support Engineers will be available round the clock to assist your Tech team and fulfill your business requirements.";
export const s5_img = "https://tailwindui.com/img/features/feature-example-2.png";

/////////////////
// SECTION 06 //
///////////////

export const s6_heading_1 = "Competitive exchange rates";
export const s6_description_1 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_2 = "No hidden fees";
export const s6_description_2 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_3 = "Transfers are instant";
export const s6_description_3 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";

/////////////////
// SECTION 07 //
///////////////

export const s7_heading = "Are you new to Git?";
export const s7_subheading = "No problem. We have resources to help you with migration and training for your team";
export const s7_title_1 = "Automated Migration";
export const s7_description_1 = "Migrate all your repositories from sources like GitHub, BitBucket, GitLab, Gogs, Gitea or any other Git Hosting service in just a few clicks. Using SVN? We can help with that too!";
export const s7_title_2 = "DevOps Expert Training";
export const s7_description_2 = "We will help your team level up their Git workflows, including developers, managers, system-admins, and other stakeholders. We’ll tailor a program for your team and environment.";

/////////////////
// SECTION 08 //
///////////////

export const s8_heading = "Integrations, Webhooks and API";
export const s8_subheading = "Easily integrate tools you already use to build software with 50+ integrations, Webhooks and API";
export const s8_title_1 = "GitHub";
export const s8_description_1 = "Quickly navigate issues in PWSLab, log Git commits in a Jira issue. Trigger comments, status updates on Jira issues based on Git SCM events.";
export const s8_title_2 = "GitLab";
export const s8_description_2 = "Webhooks can be used to update events on an external application, trigger CI jobs, update a release document, or even deploy to your production server.";
export const s8_title_3 = "Bitbucket";
export const s8_description_3 = "Require an integration with your internal applications? We've got you covered with a powerful REST API, to access all our features and services.";