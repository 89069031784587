
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
// import * as vars from "../Variables/GitVersionControlVariables";

// const features = [
//   {
//     name: vars.s6_heading_1,
//     description:
//       vars.s6_description_1,
//     icon: GlobeAltIcon,
//   },
//   {
//     name: vars.s6_heading_2,
//     description:
//       vars.s6_description_2,
//     icon: ScaleIcon,
//   },
//   {
//     name: vars.s6_heading_3,
//     description:
//       vars.s6_description_3,
//     icon: LightningBoltIcon,
//   },
// ]

export default function FeatureSectionColumn1(props) {
  return (
    <div className="py-16 sm:py-24 bg-black">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-medium text-white">{props.vars1.heading}</h2>
          <p className="mt-4 mb-4 text-lg text-white">
          {props.vars1.description}
          </p>
        </div>
        <h2 className="sr-only"></h2>
        <dl className="mt-12 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:ml-20 lg:mr-20">
          {props.vars.map((feature) => (
            <div key={feature.title} className="rounded p-12 bg-white">
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-gray-900">
                  {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.title}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
           ))} 
        </dl>
      </div>
    </div>
  )
}
