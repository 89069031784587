export default function ClientLogos() {
    return (
  <div class=" mt-12">
  <div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
    <h2 class="text-3xl font-extrabold text-white">
      The world's most innovative companies use Workflow
    </h2>
    <div class="flow-root mt-8 lg:mt-10">
      <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
        <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
          <img class="h-12" src="https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg" alt="Tuple" />
        </div>
        <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
          <img class="h-12" src="https://tailwindui.com/img/logos/mirage-logo-indigo-300.svg" alt="Mirage" />
        </div>
        <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
          <img class="h-12" src="https://tailwindui.com/img/logos/statickit-logo-indigo-300.svg" alt="StaticKit" />
        </div>
        <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
          <img class="h-12" src="https://tailwindui.com/img/logos/transistor-logo-indigo-300.svg" alt="Transistor" />
        </div>
        <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
          <img class="h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-300.svg" alt="Workcation" />
        </div>
      </div>
    </div>
  </div>
</div>
)
  }