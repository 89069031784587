    
 export default function ChatScreen() {
    return (
<div className="py-16 overflow-hidden bg-gray-50 lg:py-10">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4" width={404} height={784} fill="none" viewBox="0 0 404 784" aria-hidden="true">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>
<div className="relative mt-12 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
  <div className="relative">
    
  <div class="chat-message">
         <div class="flex items-end">
            <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-2 items-start">
               <div><span class="bg-blue-500 px-4 py-2 rounded-lg inline-block rounded-bl-none text-white ">Can be verifiedCan be v Can be verifiedCan be verifiedCan be verifiedCan be verified Can be verified on any platform using docker</span></div>
            </div>
            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-1" />
         </div>
      </div>

      <div class="chat-message my-6">
         <div class="flex items-end justify-end">
            <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-1 items-end">
               <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-gray-100 text-dark float-right">Your error message says permission denied, npm global installs must be given root privileges.</span></div>
            </div>
            <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-2" />
         </div>
      </div>

      <div class="chat-message">
         <div class="flex items-end">
            <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-2 items-start">
               <div><span class="bg-blue-500 px-4 py-2 rounded-lg inline-block rounded-bl-none text-white ">Can be verifiedCan be v Can be verifiedCan be verifiedCan be verifiedCan be verified Can be verified on any platform using docker</span></div>
            </div>
            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-1" />
         </div>
      </div>
  </div>

          <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img className="relative mx-auto" width={490} src="https://tailwindui.com/img/features/feature-example-1.png" alt="" />
          </div>
        </div>



        {/* Part 2 */}
        <svg className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full" width={404} height={784} fill="none" viewBox="0 0 404 784" aria-hidden="true">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={584} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
            <div className="relative">
    
    <div class="chat-message">
           <div class="flex items-end">
              <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-2 items-start">
                 <div><span class="bg-blue-500 px-4 py-2 rounded-lg inline-block rounded-bl-none text-white ">Can be verifiedCan be v Can be verifiedCan be verifiedCan be verifiedCan be verified Can be verified on any platform using docker</span></div>
              </div>
              <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-1" />
           </div>
        </div>
  
        <div class="chat-message my-6">
           <div class="flex items-end justify-end">
              <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-1 items-end">
                 <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-gray-100 text-dark float-right">Your error message says permission denied, npm global installs must be given root privileges.</span></div>
              </div>
              <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-2" />
           </div>
        </div>
  
        <div class="chat-message">
           <div class="flex items-end">
              <div class="flex flex-col space-y-2 text-sm max-w-xs mx-2 order-2 items-start">
                 <div><span class="bg-blue-500 px-4 py-2 rounded-lg inline-block rounded-bl-none text-white ">Can be verifiedCan be v Can be verifiedCan be verifiedCan be verifiedCan be verified Can be verified on any platform using docker</span></div>
              </div>
              <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-8 h-8 rounded-full order-1" />
           </div>
        </div>
    </div>
            </div>

            <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
              <svg className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404" aria-hidden="true">
                <defs>
                  <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img className="relative mx-auto" width={490} src="https://tailwindui.com/img/features/feature-example-2.png" alt="" />
            </div>
          </div>
        </div>

        
        </div>
        </div>
 )
}