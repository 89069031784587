// import router
import { withRouter } from "react-router-dom";
// import react components
import { useEffect } from "react";

// import js components
import Header from "../Header";
import SignIn from "../SignIn";
import GridOffset from "../GridOffset";
import AlternatingTestimonial from "../AlternatingTestimonial";
import GridList from "../GridList";
import BrandPanel from "../BrandPanel";
import FeatureSection from "../FeatureSection";
import HeaderSection from "../HeaderSection";
import FeatureSectionColumn from "../FeatureSectionColumn";
import FeatureSectionColumn1 from "../FeatureSectionColumn1";
import Footer from "../Footer";
import { CloudUploadIcon, CogIcon, LockClosedIcon, RefreshIcon, ServerIcon, ShieldCheckIcon, } from '@heroicons/react/outline';
import { InboxIcon, SparklesIcon, AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'


function GitVersionControl(props) {

    // MAIN HEADER VARIABLES

    const main_header_1 = {
        "heading": props.vars.s1_heading,
        "headinghighlight": props.vars.s1_heading_highlight,
        "description": props.vars.s1_description,
        "subheading": props.vars.s1_sub_heading,
        "img1": props.vars.s1_img_1,
        "alt1": props.vars.s1_img_1_alt,
        "img2": props.vars.s1_img_2,
        "alt2": props.vars.s1_img_2_alt,
        "img3": props.vars.s1_img_3,
        "alt3": props.vars.s1_img_3_alt,
        "card_title": props.vars.card_title,
    }

    const main_header_list = [
        { "list": props.vars.s1_list_1 },
        { "list": props.vars.s1_list_2 },
        { "list": props.vars.s1_list_3 },
        { "list": props.vars.s1_list_4 },
        { "list": props.vars.s1_list_5 },
        { "list": props.vars.s1_list_6 },
    ]

    const main_header_2 = {
        "heading": props.vars.s1_heading_2,
        "headinghighlight": props.vars.s1_heading_highlight_2,
        "description": props.vars.s1_description,
        "subheading": props.vars.s1_sub_heading,
        "img1": props.vars.s1_img_1,
        "alt1": props.vars.s1_img_1_alt,
        "img2": props.vars.s1_img_2,
        "alt2": props.vars.s1_img_2_alt,
        "img3": props.vars.s1_img_3,
        "alt3": props.vars.s1_img_3_alt,
        "card_title": props.vars.card_title,
    }

    // FEATURES VARIABLES

    const feature_header = {
        "heading": props.vars.s2_heading,
        "description": props.vars.s2_description,
    }

    const features = [
        { "name": props.vars.s2_card_1_heading, "icon": CloudUploadIcon, "description": props.vars.s2_card_1_description },
        { "name": props.vars.s2_card_2_heading, "icon": LockClosedIcon, "description": props.vars.s2_card_2_description },
        { "name": props.vars.s2_card_3_heading, "icon": RefreshIcon, "description": props.vars.s2_card_3_description },
        { "name": props.vars.s2_card_4_heading, "icon": ShieldCheckIcon, "description": props.vars.s2_card_4_description },
        { "name": props.vars.s2_card_5_heading, "icon": CogIcon, "description": props.vars.s2_card_5_description },
        { "name": props.vars.s2_card_6_heading, "icon": ServerIcon, "description": props.vars.s2_card_6_description },
    ]

    // ALTERNATE BENEFITS
    const alternate_benefits_heading1 = {
        heading: props.vars.s3_heading_1,
        sub_heading: props.vars.s3_subheading_1
    }
    const alternate_benefits_heading2 = {
        heading: props.vars.s3_heading_2,
        sub_heading: props.vars.s3_subheading_2
    }
    const alternate_benefits_heading3 = {
        heading: props.vars.s3_heading_3,
        sub_heading: props.vars.s3_subheading_3
    }
    const alternate_benefits1 = [
        { id: 1, name: props.vars.s3_name_1, description: props.vars.s3_list_1, icon: GlobeAltIcon, },
        { id: 2, name: props.vars.s3_name_2, description: props.vars.s3_list_2, icon: ScaleIcon, },
        { id: 3, name: props.vars.s3_name_3, description: props.vars.s3_list_3, icon: LightningBoltIcon, },
    ]

    const alternate_benefits2 = [
        { id: 1, name: props.vars.s3_name_4, description: props.vars.s3_list_4, icon: GlobeAltIcon, },
        { id: 2, name: props.vars.s3_name_5, description: props.vars.s3_list_5, icon: ScaleIcon, },
        { id: 3, name: props.vars.s3_name_6, description: props.vars.s3_list_6, icon: LightningBoltIcon, },
    ]

    const alternate_benefits3 = [
        { id: 1, name: props.vars.s3_name_7, description: props.vars.s3_list_7, icon: GlobeAltIcon, },
        { id: 2, name: props.vars.s3_name_8, description: props.vars.s3_list_8, icon: ScaleIcon, },
        { id: 3, name: props.vars.s3_name_9, description: props.vars.s3_list_9, icon: LightningBoltIcon, },
    ]

    // GRID FEATURES
    const grid_features_header = { heading: props.vars.s4_heading, sub_heading: props.vars.s4_description }

    const grid_features = [
        { name: props.vars.s4_title_1, description: props.vars.s4_description_1, },
        { name: props.vars.s4_title_2, description: props.vars.s4_description_2, },
        { name: props.vars.s4_title_3, description: props.vars.s4_description_3, },
        { name: props.vars.s4_title_4, description: props.vars.s4_description_4, },
        { name: props.vars.s4_title_5, description: props.vars.s4_description_5, },
        { name: props.vars.s4_title_6, description: props.vars.s4_description_6, },
        { name: props.vars.s4_title_7, description: props.vars.s4_description_7, },
        { name: props.vars.s4_title_8, description: props.vars.s4_description_8, },
        { name: props.vars.s4_title_9, description: props.vars.s4_description_9, },
        { name: props.vars.s4_title_10, description: props.vars.s4_description_10, },
        { name: props.vars.s4_title_11, description: props.vars.s4_description_11, },
        { name: props.vars.s4_title_12, description: props.vars.s4_description_12, },
    ]

    //CTA 
    const cta = {
        heading: props.vars.heading,
        description: props.vars.description,
        btn_name: props.vars.btn_name
    }

    // FEATURE BLOCK
    const feature_block_heading = { heading: props.vars.s5_heading, img: props.vars.s5_img, }
    const feature_block = [
        { id: 1, name: props.vars.s5_title_1, description: props.vars.s5_description_1, icon: AnnotationIcon, },
        { id: 2, name: props.vars.s5_title_2, description: props.vars.s5_description_2, icon: MailIcon, },
    ]


    const header_section_var_1 = { "heading": props.vars.s7_heading, "description": props.vars.s7_subheading, }

    const feature_section_var_1 = [
        { "title": props.vars.s7_title_1, "description": props.vars.s7_description_1 },
        { "title": props.vars.s7_title_2, "description": props.vars.s7_description_2 },
    ]

    const header_section_var_2 = { "heading": props.vars.s8_heading, "description": props.vars.s8_subheading }

    const feature_section_var_2 = [
        { "title": props.vars.s8_title_1, "description": props.vars.s8_description_1 },
        { "title": props.vars.s8_title_2, "description": props.vars.s8_description_2 },
        { "title": props.vars.s8_title_3, "description": props.vars.s8_description_3 },
    ]

    return (
        <>
            <Header />

            <SignIn vars={main_header_1} vars1={main_header_list} />

            <GridOffset vars={feature_header} vars1={features} />

            <AlternatingTestimonial vars={alternate_benefits_heading1} vars1={alternate_benefits_heading2} vars2={alternate_benefits_heading3} vars3={alternate_benefits1} vars4={alternate_benefits2} vars5={alternate_benefits3} />

            <GridList vars={grid_features_header} vars1={grid_features} />

            <BrandPanel vars={cta} />

            <FeatureSection vars={feature_block_heading} vars1={feature_block} />

            <FeatureSectionColumn1 vars1={header_section_var_1} vars={feature_section_var_1} />

            <HeaderSection vars={header_section_var_2} />

            <FeatureSectionColumn vars={feature_section_var_2} />

            <SignIn vars={main_header_2} vars1={main_header_list} />

            <Footer />
        </>
    )

}


export default withRouter(GitVersionControl);