import * as vars from "../Variables/HomepageVariables";

export default function FooterBanner() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-4">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={vars.s2_img_src_1} alt={vars.s2_img_src_1_alt} />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={vars.s2_img_src_2} alt={vars.s2_img_src_2_alt} />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={vars.s2_img_src_3} alt={vars.s2_img_src_3_alt} />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-12"
              src={vars.s2_img_src_4}
              alt={vars.s2_img_src_4_alt}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img
              className="h-12"
              src={vars.s2_img_src_5}
              alt={vars.s2_img_src_5_alt}
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={vars.s2_img_src_6} alt={vars.s2_img_src_6_alt} />
          </div>
        </div>
      </div>
    </div>
  )
}
