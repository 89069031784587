

export default function BrandPanel(props) {
  return (
    <div className="bg-indigo-700">
      <div className="max-w-2xl px-4 py-16 mx-auto text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">{props.vars.heading}</span>
          {/* <span className="block">{vars.s8_heading_2}</span> */}
        </h2>
        <p className="mt-4 text-lg leading-6 text-indigo-200">
          {props.vars.description}
        </p>
        <a href="#" className="inline-flex items-center justify-center w-full px-5 py-3 mt-8 text-base font-medium text-indigo-600 bg-white border border-transparent rounded-md hover:bg-indigo-50 sm:w-auto">
          {props.vars.btn_name}
        </a>
      </div>
    </div>
  )
}
