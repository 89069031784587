import { ExternalLinkIcon } from '@heroicons/react/solid'
import * as vars from "../Variables/HomepageVariables";


export default function CTASection() {
  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-blue-500 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="object-cover w-full h-full"
          src={vars.s5_img}
          alt=""
        />
      </div>
      <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold tracking-wider text-gray-300 uppercase">{vars.s5_heading}</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">{vars.s5_sub_heading}</p>
          <p className="mt-3 text-lg text-gray-300">
            {vars.s5_description}
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
              >
                {
                  vars.s5_btn
                }
                <ExternalLinkIcon className="w-5 h-5 ml-3 -mr-1 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
