import React, { useState, useEffect } from 'react';
import { ghost_api_url } from "./Urls";
import * as vars from "../Variables/HomepageVariables";

const axios = require('axios');


// var posts = [
// {
//   title: vars.s7_card_1_title,
//   href: '#',
//   category: { name: vars.s7_card_1_category, href: '#' },
//   description:
//     vars.s7_card_1_description,
//   date: vars.s7_card_1_date,
//   datetime: vars.s7_card_1_datetime,
//   imageUrl:
//     vars.s7_card_1_img_1,
//   readingTime: vars.s7_card_1_readingtime,
//   author: {
//     name: vars.s7_card_1_name,
//     href: '#',
//     imageUrl:
//       vars.s7_card_1_img_2,
//   },
// },
// {
//   title: vars.s7_card_2_title,
//   href: '#',
//   category: { name: vars.s7_card_2_category, href: '#' },
//   description:
//     vars.s7_card_2_description,
//   date: vars.s7_card_2_date,
//   datetime: vars.s7_card_2_datetime,
//   imageUrl:
//     vars.s7_card_2_img_1,
//   readingTime: vars.s7_card_2_readingtime,
//   author: {
//     name: vars.s7_card_2_name,
//     href: '#',
//     imageUrl:
//       vars.s7_card_2_img_2,
//   },
// },
// {
//   title: vars.s7_card_3_title,
//   href: '#',
//   category: { name: vars.s7_card_3_category, href: '#' },
//   description:
//     vars.s7_card_3_description,
//   date: vars.s7_card_3_date,
//   datetime: vars.s7_card_3_datetime,
//   imageUrl:
//     vars.s7_card_3_img_1,
//   readingTime: vars.s7_card_3_readingtime,
//   author: {
//     name: vars.s7_card_3_name,
//     href: '#',
//     imageUrl:
//       vars.s7_card_3_img_2,
//   },
// },
// ]


export default function Cards() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // call function to get posts
    getPosts();
  })

  function getPosts() {

    axios.get(ghost_api_url)
      .then(function (response) {

        var posts = [];
        // map through response and filter data and push the data to a posts array
        response.data.posts.map((res, index) => {
          posts.push(
            {
              title: res.title,
              href: res.url,
              category: { name: (res.tags.length > 0) ? res.tags[0].name : "\xa0", href: (res.tags.length > 0) ? res.tags[0].url : "#" },
              description: "",
              date: new Date(res.published_at).toDateString(),
              datetime: new Date(res.published_at).toDateString(),
              imageUrl: res.feature_image,
              readingTime: res.reading_time && res.reading_time + " min",
              author: {
                name: (res.authors.length > 0) ? res.authors[0].name : "",
                href: (res.authors.length > 0) ? res.authors[0].url : "#",
                imageUrl: (res.authors.length > 0) ? res.authors[0].profile_image : "",
              },
            },
          )
        });

        // set state for posts
        setPosts(posts);
      })
      .catch(function (error) {
        // console.log(error);
      })
  }

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">{vars.s7_heading}</h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {
              vars.s7_sub_heading
            }
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a href={post.category.href} target="__blank" className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} target="__blank" className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href} target="__blank">
                      <span className="sr-only">{post.author.name}</span>
                      <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author.href} target="__blank" className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
