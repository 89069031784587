/////////////////
// SECTION 01 //
///////////////

export const s1_heading = 'Introducing';
export const s1_heading_highlight = ' VegaMaster';
export const s1_sub_heading = 'Single integrated agile solution to manage DevOps and Automation on GitLab without any limitations.';
export const s1_btn_1 = 'Get Started';
export const s1_btn_2 = 'Live Demo';

/////////////////
// SECTION 02 //
///////////////

export const s2_img_src_1 = 'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg';
export const s2_img_src_1_alt = 'Tuple';
export const s2_img_src_2 = 'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg';
export const s2_img_src_2_alt = 'Mirage';
export const s2_img_src_3 = 'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg';
export const s2_img_src_3_alt = 'StaticKit';
export const s2_img_src_4 = 'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg';
export const s2_img_src_4_alt = 'Transistor';
export const s2_img_src_5 = "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg";
export const s2_img_src_5_alt = "Workcation";
export const s2_img_src_6 = "https://tailwindui.com/img/logos/mirage-logo-gray-400.svg";
export const s2_img_src_6_alt = 'Mirage';

/////////////////
// SECTION 03 //
///////////////

export const s3_heading = 'Everything NOT in GitLab CE';
export const s3_sub_heading = 'VegaMaster offers effective and affordable solutions to effectively manage DevOps on GitLab.';
export const s3_card_name_1 = 'Products and Teams';
export const s3_card_description_1 = "Organize GitLab projects and members as Products and Teams to control access and administration. Centrally manage activity, reports and configurations.";
export const s3_badge_1 = 'Substitute - GitLab Groups';
export const s3_card_name_2 = "Git Controls";
export const s3_card_description_2 = "Gain control over which commits get pushed/merged into your Git repositories by configuring Git Controls at Product or Repository level.";
export const s3_badge_2 = 'Substitute - GitLab Push Rules';
export const s3_card_name_3 = "Fully-Managed GitLab";
export const s3_card_description_3 = "Use automated off-site data-backups, GitLab updates, SSL Renewals and everything else that is needed for a performant GitLab instance.";
export const s3_badge_3 = 'Substitute - Self-Managed GitLab';
/////////////////
// SECTION 04 //
///////////////

export const s4_heading_highlight = "Everything you need";
export const s4_heading = "Upcoming Features";
export const s4_sub_heading = "We release new features and improvements every week.";
export const s4_name_1 = "Product Dashboards";
export const s4_description_1 = "An overview of all the projects in a product, displaying latest activity reports. Analyse key metrics like issues and CI/CD pipelines.";
export const s4_name_2 = "Schedule Pipelines";
export const s4_description_2 = "CI/CD pipelines can be triggered at the exact time you set, overcoming the limitations in GitLab Pipeline Schedules.";
export const s4_name_3 = "Code Quality Analysis";
export const s4_description_3 = "A historical overview of your projects Code Quality Reports to ensure it stays simple, readable, easy to maintain and contribute.";
export const s4_name_4 = "More Git Controls";
export const s4_description_4 = "We are adding more Git Controls for your repos like Merge Approvals, Deny deletions of Tags/Branches, Prohibited filenames etc.";
export const s4_name_5 = "Security Dashboards";
export const s4_description_5 = "Data from GitLab Security CI/CD jobs is analysed to display vulnerabilities identified in your projects over time.";
export const s4_name_6 = "Custom Workflows";
export const s4_description_6 = "Create and configure customisable business workflows with different triggers to perform desired actions.";
export const s4_name_7 = "Reporting";
export const s4_description_7 = "Find what you need with advanced filters, bulk actions, and quick views.";
export const s4_name_8 = "Mobile app";
export const s4_description_8 = "Find what you need with advanced filters, bulk actions, and quick views.";

/////////////////
// SECTION 05 //
///////////////

export const s5_img = "https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply";
export const s5_heading = "Award winning support";
export const s5_sub_heading = "We’re here to help";
export const s5_description = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a
                                scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum
                                tincidunt duis.`;
export const s5_btn = "Visit the help center";

/////////////////
// SECTION 06 //
///////////////

export const s6_img_1 = "https://tailwindui.com/img/logos/workcation-logo-indigo-600-mark-gray-800-and-indigo-600-text.svg";
export const s6_img_1_alt = "Workcation";
export const s6_description = `&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente
alias molestiae. Numquam corrupti in laborum sed rerum et corporis.&rdquo;`;
export const s6_img_2 = "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
export const s6_name = "name";
export const s6_designation = "CEO, Workcation";

/////////////////
// SECTION 07 //
///////////////

export const s7_heading = "From the blog";
export const s7_sub_heading = "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.";

export const s7_card_1_title = "Boost your conversion rate";
export const s7_card_1_category = "Article";
export const s7_card_1_description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.";
export const s7_card_1_img_1 = "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80";
export const s7_card_1_img_2 = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
export const s7_card_1_name = "Roel Aufderehar";
export const s7_card_1_date = 'Mar 16, 2020';
export const s7_card_1_datetime = '2020-03-16';
export const s7_card_1_readingtime = '6 min';

export const s7_card_2_title = "How to use search engine optimization to drive sales";
export const s7_card_2_category = "Video";
export const s7_card_2_description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.";
export const s7_card_2_img_1 = "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80";
export const s7_card_2_img_2 = "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
export const s7_card_2_name = "Brenna Goyette";
export const s7_card_2_date = 'Mar 16, 2020';
export const s7_card_2_datetime = '2020-03-16';
export const s7_card_2_readingtime = '4 min';

export const s7_card_3_title = "Boost your conversion rate";
export const s7_card_3_category = "Article";
export const s7_card_3_description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.";
export const s7_card_3_img_1 = "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80";
export const s7_card_3_img_2 = "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
export const s7_card_3_name = "Roel Aufderehar";
export const s7_card_3_date = 'Mar 16, 2020';
export const s7_card_3_datetime = '2020-03-16';
export const s7_card_3_readingtime = '6 min';

/////////////////
// SECTION 08 //
///////////////

export const s8_heading_1 = 'Continue for FREE Forever';
export const s8_heading_2 = "";
export const s8_description = 'Upgrade when ready';
export const s8_btn_name = 'Call us Now!';


