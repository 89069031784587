
import { CheckIcon, ViewBoardsIcon } from '@heroicons/react/outline'
import * as vars from "../Variables/HomepageVariables";


const features = [
  { name: vars.s4_name_1, description: vars.s4_description_1 },
  { name: vars.s4_name_2, description: vars.s4_description_2 },
  { name: vars.s4_name_3, description: vars.s4_description_3 },
  { name: vars.s4_name_4, description: vars.s4_description_4 },
  { name: vars.s4_name_5, description: vars.s4_description_5 },
  { name: vars.s4_name_6, description: vars.s4_description_6 },
  { name: vars.s4_name_7, description: vars.s4_description_7 },
  { name: vars.s4_name_8, description: vars.s4_description_8 },
]

export default function platForm() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">{vars.s4_heading_highlight}</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">{vars.s4_heading}</p>
          <p className="mt-4 text-lg text-gray-500">
            {vars.s4_sub_heading}
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
