
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
// import * as vars from "../Variables/GitVersionControlVariables";

// const features = [
//   {
//     name: vars.s6_heading_1,
//     description:
//       vars.s6_description_1,
//     icon: GlobeAltIcon,
//   },
//   {
//     name: vars.s6_heading_2,
//     description:
//       vars.s6_description_2,
//     icon: ScaleIcon,
//   },
//   {
//     name: vars.s6_heading_3,
//     description:
//       vars.s6_description_3,
//     icon: LightningBoltIcon,
//   },
// ]

export default function FeatureSectionColumn(props) {
  return (
    <div className="pb-16 sm:pb-24 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only"></h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {props.vars.map((feature) => (
            <div key={feature.title}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.title}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
           ))} 
        </dl>
      </div>
    </div>
  )
}
