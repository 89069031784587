/////////////////
// SECTION 01 //
///////////////

// export const s1_title_1 = "We're hiring";
// export const s1_title_2 = 'Visit our careers page';
export const s1_heading = 'CI/CD Automation to';
export const s1_heading_highlight = 'Build, Test and Deploy';
export const s1_description= `Integrated CI/CD to automate all your manual software development operations from builds, testing to deployment.`;
export const s1_sub_heading = "Used by";
export const s1_img_1 = "https://tailwindui.com/img/logos/tuple-logo-gray-400.svg";
export const s1_img_1_alt = "Tuple";
export const s1_img_2 = "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg";
export const s1_img_2_alt = "Workcation";
export const s1_img_3 = "https://tailwindui.com/img/logos/statickit-logo-gray-400.svg";
export const s1_img_3_alt = "StaticKit";
export const s1_heading_2 = 'Scalable CI/CD for all teams';
// export const s1_heading_highlight_2 = 'new Centralized';
export const s1_description_2 = "From code to deployment, we have got you covered. With PWSLab, build quality products faster.";
export const card_title = 'Build, test and deploy automatically';
export const s1_list_1 = 'Multi-platform support';
export const s1_list_2 = 'Multi-language support';
export const s1_list_3 = 'Configuration as Code';
export const s1_list_4 = 'Auto Deployments';
export const s1_list_5 = 'Docker and Kubernetes';
export const s1_list_6 = '24/7 CI/CD Support';

/////////////////
// SECTION 02 //
///////////////

export const s2_heading = <p><b>Fully Automated</b> CI/CD for teams</p>;
export const s2_description = "Build, test and deploy from anywhere, anytime in just a few clicks";
export const s2_card_1_heading = "Built-in Private CI/CD";
export const s2_card_1_description = `With PWSLab, CI/CD is built-in to the secure system along with your project code. No seperate servers to manage, repos to sync, or integrations required.`;
export const s2_card_2_heading = "Detect Errors Early";
export const s2_card_2_description = `Fix issues early in the release pipeline. Leverage Automated testing to Test continuously, release faster with more confidence.`;
export const s2_card_3_heading = "Fast Customer Feedback";
export const s2_card_3_description = `You can get fast and frequent customer feedback on the changes released. Release incrementally on time, and improve your business trust in the market.`;
export const s2_card_4_heading = "Reduce Development Costs";
export const s2_card_4_description = `Automated CI/CD pipelines reduces time and effort spent on manual operations, allowing developers to focus on their primary job - building software.`;
export const s2_card_5_heading = "Track Project Releases";
export const s2_card_5_description = `Release anywhere, anytime. All the stakeholders can track a Release progress and developers contributions, reducing bottlenecks with more visibility.`;
export const s2_card_6_heading = "24/7 CI/CD Support";
export const s2_card_6_description = `PWSLab DevOps Engineers are available round the clock as your external DevOps team to architect the CI/CD configurations and meet your project goals.`;

/////////////////
// SECTION 03 //
///////////////

export const s3_heading = 'Lorem Ipsum' 
export const s3_description = `Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
viverra dui tellus ornare pharetra.`;
export const s3_heading_1 = <p><strong>Detect issues early</strong> with Automated Builds</p>;
export const s3_subheading_1 = "Improve Code Quality with Code Review";
// export const s3_name_1 = "Automated Builds";
export const s3_list_1 = "All the Code changes by developers are checked-in to a shared Git repository and are verified by an automated build, allowing teams to detect problems early.";
// export const s3_name_2 = "Automated Testing";
export const s3_list_2 = "Run unit and integration tests to check if code changes are valid before they are published to environments.";
// export const s3_name_3 = "Quick Feedback Loop";
export const s3_list_3 = "Distribute workloads across multiple machines for faster execution and encourage quick feedback loop.";
export const s3_heading_2 = <p><b>Faster feedback</b> with Continuous Testing</p>;
export const s3_subheading_2 = "Improve Code Quality with Co";
// export const s3_name_4 = "Test Scripts in CI";
export const s3_list_4 = "Include Testing scripts to the Continuous Integration pipeline and ensure that the failure of a test should result in a failed build.";
// export const s3_name_5 = "Faster Feedback Cycle";
export const s3_list_5 = "Test automation helps you reduce the feedback cycle and bring faster validation of code.";
// export const s3_name_6 = "Avoid the Limitations";
export const s3_list_6 = "Manual testing has limitations, Automation allows you to write new tests and add them to your CI pipeline.";
export const s3_heading_3 = <p><b>Fully Automated</b> Deployments</p>;
export const s3_subheading_3 = "Improve Code Quality with Co";
// export const s3_name_7 = "Analyze Code Quality";
export const s3_list_7 = "Every code change goes through a pre-defined CI/CD pipeline and is put into production automatically, resulting in many production deployments every day.";
// export const s3_name_8 = "Quick Audits";
export const s3_list_8 = "Run secure deployments without performing any manual steps, or contribution hand-offs with teams.";
// export const s3_name_9 = "Security First";
export const s3_list_9 = "Consistently provision and configure servers. Manage dependencies, on the fly to meet real-time demands.";




/////////////////
// SECTION 04 //
///////////////

export const s4_heading = <p><b>Integrated and Scalable</b> CI/CD</p>;
export const s4_description = `All that you expect from a CI/CD solution. No plugins required.`;
export const s4_title_1 = "Support for every Platform";
export const s4_description_1 = "Configure CI/CD automation for all languages like Java, PHP, NodeJS, Python, .NET Code, Ruby, C and many others.";
export const s4_title_2 = "Configuration as Code";
export const s4_description_2 = "Write and manage your project's CI/CD configuration in a single version controlled pwslab.yml file.";
export const s4_title_3 = "Support for multiple OS";
export const s4_description_3 = "You can run CI/CD pipelines on all major operating systems like - Linux distros, Windows, macOS.";
export const s4_title_4 = "Pipelines for Git branches";
export const s4_description_4 = "CI/CD pipelines can be customised for every Git branch, making it easier to maintain workflows for per branch.";
export const s4_title_5 = "Secret Variables";
export const s4_description_5 = "Securely store and manage secrets to be used in your project's CI/CD configuration for different environments.";
export const s4_title_6 = "Multiple Environments";
export const s4_description_6 = "Configure and manage multiple deployment environments for projects and monitor their deployment history.";
export const s4_title_7 = "Parallel Pipelines";
export const s4_description_7 = "With the power of GitLab CI/CD we split pipelines over multiple machines to execute CI/CD faster.";
export const s4_title_8 = "Infrastructure as Code";
export const s4_description_8 = "Cloud integrations, Ansible integrations to help you spin up and manage your infra resources with CI/CD.";
export const s4_title_9 = "CI/CD Artifacts";
export const s4_description_9 = "Upload pipeline artifacts such as test reports, binaries etc. generated from CI/CD, to browse or download them later.";
export const s4_title_10 = "Built-in Container Registry";
export const s4_description_10 = "A built-in private Docker Container Registry to upload, manage and share all your project's Docker images.";
export const s4_title_11 = "Support for Kubernetes";
export const s4_description_11 = "Connect Kubernetes clusters to your projects as deployment environments and execute CI/CD pipelines.";
export const s4_title_12 = "Autoscaling Instances";
export const s4_description_12 = "With the power of Infra as Code and Cloud integrations, spin up and down instances for builds and save costs.";


/////////////////
//CTA///////////
///////////////

export const heading = "Continue for FREE Forever";
export const description = "Upgrade when ready";
export const btn_name = "Call us Now!";

/////////////////
// SECTION 05 //
///////////////

export const s5_heading = <p><b>Use a single DevOps solution</b> to manage entire Software Development Lifecycle</p>;
export const s5_title_1 = "Mobile notifications";
export const s5_description_1 = "From Software Development till Customer Deployment and Delivery, we ensure every aspect is offered in a single interface, at a highly competitive and affordable pricing.";
export const s5_title_2 = "Reminder emails";
export const s5_description_2 = "Unlike other DIY solutions, our Expert DevOps Support Engineers will be available round the clock to assist your Tech team and fulfill your business requirements.";
export const s5_img = "https://tailwindui.com/img/features/feature-example-2.png";

/////////////////
// SECTION 06 //
///////////////

export const s6_heading_1 = "Competitive exchange rates";
export const s6_description_1 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_2 = "No hidden fees";
export const s6_description_2 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_3 = "Transfers are instant";
export const s6_description_3 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";

/////////////////
// SECTION 07 //
///////////////

export const s7_heading = <p>Are you <b>new</b> to CI/CD?</p>;
export const s7_subheading = "No problem, we have resources to help you with CI/CD configuration and training your team";
export const s7_title_1 = "24/7 CI/CD Support";
export const s7_description_1 = "Our Expert DevOps Support Engineers will understand your requirements carefully and accordingly configure the Build, Testing and Deployment pipelines for your projects.";
export const s7_title_2 = "DevOps Expert Training";
export const s7_description_2 = "We will help your team level up their workflow, including developers, non-developers, managers, system admins, and other stakeholders. We’ll tailor a program for your team and environment.";

/////////////////
// SECTION 08 //
///////////////

export const s8_heading = "Integrations, Webhooks and API";
export const s8_subheading = "Easily integrate tools you already use to build software with 50+ integrations, Webhooks and API";
export const s8_title_1 = "Integrate Jira with PWSLab";
export const s8_description_1 = "Quickly navigate issues in PWSLab, log Git commits in a Jira issue. Trigger comments, status updates on Jira issues based on Git SCM events.";
export const s8_title_2 = "Webhooks";
export const s8_description_2 = "Webhooks can be used to update events on an external application, trigger CI jobs, update a release document, or even deploy to your production server.";
export const s8_title_3 = "Powerful API";
export const s8_description_3 = "Require an integration with your internal applications? We've got you covered with a powerful REST API, to access all our features and services.";