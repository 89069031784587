// import router
import { withRouter } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import FAQSection from "../FAQSection";
import FooterBanner from "../FooterBanner";
import PricingSection from "../PricingSection";
import HeaderSection from "../HeaderSection";
import FeatureSectionColumn from "../FeatureSectionColumn";
import CTASection from "../CTASection";
import Stats from "../Stats";


function PricingPage(props) {

  const header_section_var_2 = { "heading": props.vars.heading, "description": props.vars.description }

  const feature_section_var_2 = [
    { "title": props.vars.s3_title_1, "description": props.vars.s3_description_1 },
    { "title": props.vars.s3_title_2, "description": props.vars.s3_description_2 },
    { "title": props.vars.s3_title_3, "description": props.vars.s3_description_3 },
]

return (

<>
<Header />

  <main>

    <PricingSection />

    <Stats />

    <HeaderSection vars={header_section_var_2} />

    <FeatureSectionColumn vars={feature_section_var_2} />

    <FAQSection />

    <FooterBanner />
    
    <CTASection />

    <Footer />

  </main>
  </>
)}
export default withRouter(PricingPage);