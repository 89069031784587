/////////////////
// SECTION 01 //
///////////////

// export const s1_title_1 = "We're hiring";
// export const s1_title_2 = 'Visit our careers page';
export const s1_heading = 'Integrate Security Culture';
export const s1_heading_highlight = 'within DevOps';
export const s1_description= `Bring DevOps and Security together to enforce security and risk management best practices.`;
export const s1_sub_heading = "Used by";
export const s1_img_1 = "https://tailwindui.com/img/logos/tuple-logo-gray-400.svg";
export const s1_img_1_alt = "Tuple";
export const s1_img_2 = "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg";
export const s1_img_2_alt = "Workcation";
export const s1_img_3 = "https://tailwindui.com/img/logos/statickit-logo-gray-400.svg";
export const s1_img_3_alt = "StaticKit";
export const s1_heading_2 = 'Build Secure Software at the Speed of DevOps';
// export const s1_heading_highlight_2 = 'new Centralized';
export const s1_description_2 = "From code to deployment, we have got you covered. With PWSLab, build quality products faster.";
export const card_title = 'Build, test and deploy automatically';
export const s1_list_1 = 'Multi-platform support';
export const s1_list_2 = 'Multi-language support';
export const s1_list_3 = 'Configuration as Code';
export const s1_list_4 = 'Auto Deployments';
export const s1_list_5 = 'Docker and Kubernetes';
export const s1_list_6 = '24/7 CI/CD Support';

/////////////////
// SECTION 02 //
///////////////

export const s2_heading = <p><b>Release Secure,</b> Quality Code Faster</p>;
export const s2_description = "Build secure software at the speed of DevOps";
export const s2_card_1_heading = "Secret Detection";
export const s2_card_1_description = `Prevent unintentional commits of secret credentials to the remote repositories and save the sensitive information from being exposed.`;
export const s2_card_2_heading = "Code Quality";
export const s2_card_2_description = `One place to provide a shared vision of code quality for developers, tech leads and managers.`;
export const s2_card_3_heading = "Static Application Security Testing";
export const s2_card_3_description = `Test and secure code before the final update is deployed to production without compiling the code with SAST.`;
export const s2_card_4_heading = "Dynamic Application Security Testing";
export const s2_card_4_description = `Find vulnerabilities in web applications while they are running in production with DAST.`;
export const s2_card_5_heading = "Container Scanning";
export const s2_card_5_description = `Scan Docker image and approve them before ever entering “Running” state in the production clusters.`;
export const s2_card_6_heading = "Browser Performance Testing";
export const s2_card_6_description = `Quickly determine the rendering performance impact of pending code changes in the browser.`;

/////////////////
// SECTION 03 //
///////////////

export const s3_heading = 'Lorem Ipsum' 
export const s3_description = `Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
viverra dui tellus ornare pharetra.`;
export const s3_heading_1 = <p><strong>Cost Reduction</strong> with Automation</p>;
export const s3_subheading_1 = "Improve Code Quality with Code Review";
// export const s3_name_1 = "Automated Builds";
export const s3_list_1 = "Detect and fix security issues during the development phases and save resources.";
// export const s3_name_2 = "Automated Testing";
export const s3_list_2 = "Save time in manually configuration of security consoles by Security Architects.";
// export const s3_name_3 = "Quick Feedback Loop";
export const s3_list_3 = "Accelerates the speed of recovery if and when any security incidents happen saving time, money and company's reputation.";
export const s3_heading_2 = `Enhance Visibility`;
export const s3_subheading_2 = "Improve Code Quality with Co";
// export const s3_name_4 = "Test Scripts in CI";
export const s3_list_4 = "Foster a culture of openness and transparency at the deepest level of your organization's hierarchy.";
// export const s3_name_5 = "Faster Feedback Cycle";
export const s3_list_5 = "Avoid imprecision and inaccuracy when making decisions regarding incident response, resource management and event handling.";
// export const s3_name_6 = "Avoid the Limitations";
export const s3_list_6 = "Provide a shared vision of code quality for developers, tech leads and managers.";
export const s3_heading_3 = `Increase Automation`;
export const s3_subheading_3 = "Improve Code Quality with Co";
// export const s3_name_7 = "Analyze Code Quality";
export const s3_list_7 = "Avoid human error by automating tests and enable more excellent coverage, consistency and predictable processes.";
// export const s3_name_8 = "Quick Audits";
export const s3_list_8 = "Create a closed-loop process for testing and reporting, thereby ensuring that all security concerns are immediately resolved.";
// export const s3_name_9 = "Security First";
export const s3_list_9 = "Allow security professionals to focus their attention towards threat remediation and elimination of strategic risk.";




/////////////////
// SECTION 04 //
///////////////

export const s4_heading = <p><b>Powerful</b> DevSecOps Features</p>;
export const s4_description = `Developed and Designed for Developers`;
export const s4_title_1 = "Automatic Quality Checks";
export const s4_description_1 = "Build security testing in the software delivery pipeline and set up approval checkpoints in your release pipelines.";
export const s4_title_2 = "Automatic Audit Trail";
export const s4_description_2 = "Collect evidence for audits and present it with ease in a single system of record for the end-to-end release process.";
export const s4_title_3 = "Role-Based Access Control";
export const s4_description_3 = "Get role-based access for individuals that provides granular level permissions for all release and deployment tasks.";
export const s4_title_4 = "Infrastructure Management";
export const s4_description_4 = "Get a single place to manage infrastructure, so you can more easily control access to target systems.";
export const s4_title_5 = "Stay Updated";
export const s4_description_5 = "Gain visibility into the status of every software component at any time, and capture audit trails automatically.";
export const s4_title_6 = "Respond Quickly";
export const s4_description_6 = "Respond quickly with audit trails and full visibility into both development changes and environment status.";
export const s4_title_7 = "Application Scanning";
export const s4_description_7 = "Scan the application to spot potential vulnerabilities and bugs before the code is merged.";
export const s4_title_8 = "Quick Fixes";
export const s4_description_8 = "Quickly perform fixes of vulnerabilities throughout the software development lifecycle.";
export const s4_title_9 = "Detect Secrets";
export const s4_description_9 = "Prevent secrets from accidentally getting leak into your Git history. Each commit is scanned for secrets within SAST.";
export const s4_title_10 = "Faster Delivery";
export const s4_description_10 = "Allow developers to focus on shipping features by integrating security within the software delivery pipeline.";
export const s4_title_11 = "Image Monitoring";
export const s4_description_11 = "Monitor your container images for newly discovered vulnerabilities and base image updates.";
export const s4_title_12 = "Analyze Code";
export const s4_description_12 = "Create an automated feedback loop by analyzing code as soon as it’s written by the developers.";


/////////////////
//CTA///////////
///////////////

export const heading = "Continue for FREE Forever";
export const description = "Upgrade when ready";
export const btn_name = "Call us Now!";

/////////////////
// SECTION 05 //
///////////////

export const s5_heading = <p><b>Use a single DevOps solution</b> to manage entire Software Development Lifecycle</p>;
export const s5_title_1 = "Mobile notifications";
export const s5_description_1 = "From Software Development till Customer Deployment and Delivery, we ensure every aspect is offered in a single interface, at a highly competitive and affordable pricing.";
export const s5_title_2 = "Reminder emails";
export const s5_description_2 = "Unlike other DIY solutions, our Expert DevOps Support Engineers will be available round the clock to assist your Tech team and fulfill your business requirements.";
export const s5_img = "https://tailwindui.com/img/features/feature-example-2.png";

/////////////////
// SECTION 06 //
///////////////

export const s6_heading_1 = "Competitive exchange rates";
export const s6_description_1 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_2 = "No hidden fees";
export const s6_description_2 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_3 = "Transfers are instant";
export const s6_description_3 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";

/////////////////
// SECTION 07 //
///////////////

export const s7_heading = <p>Are you <b>new</b> to DevSecOps?</p>;
export const s7_subheading = "No problem, we have resources to help you with CI/CD configuration and training your team";
export const s7_title_1 = "24/7 CI/CD Support";
export const s7_description_1 = "Our Expert DevOps Support Engineers will understand your requirements carefully and accordingly configure the Build, Testing and Deployment pipelines for your projects.";
export const s7_title_2 = "DevOps Expert Training";
export const s7_description_2 = "We will help your team level up their workflow, including developers, non-developers, managers, system admins, and other stakeholders. We’ll tailor a program for your team and environment.";

/////////////////
// SECTION 08 //
///////////////

export const s8_heading = "Integrations, Webhooks and API";
export const s8_subheading = "Easily integrate tools you already use to build software with 50+ integrations, Webhooks and API";
export const s8_title_1 = "Integrate Jira with PWSLab";
export const s8_description_1 = "Quickly navigate issues in PWSLab, log Git commits in a Jira issue. Trigger comments, status updates on Jira issues based on Git SCM events.";
export const s8_title_2 = "Webhooks";
export const s8_description_2 = "Webhooks can be used to update events on an external application, trigger CI jobs, update a release document, or even deploy to your production server.";
export const s8_title_3 = "Powerful API";
export const s8_description_3 = "Require an integration with your internal applications? We've got you covered with a powerful REST API, to access all our features and services.";