import * as vars from "../Variables/HomepageVariables";
import { ViewBoardsIcon } from "@heroicons/react/outline";

export default function Testimonial() {
    return (
      <section className="py-12 overflow-hidden bg-gray-50 md:py-20 lg:py-24">
        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <svg
            className="absolute transform top-full right-full translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <title id="svg-workcation">Workcation</title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
          </svg>
  
          <div className="relative">
            <img
              className="h-8 mx-auto"
              src={vars.s6_img_1}
              alt={vars.s6_img_1_alt}
            />
            <blockquote className="mt-10">
              <div className="max-w-3xl mx-auto text-2xl font-medium leading-9 text-center text-gray-900">
                <p>
                  {
                    vars.s6_description
                  }
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="w-10 h-10 mx-auto rounded-full"
                      src={vars.s6_img_2}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{vars.s6_name}</div>
  
                    <svg className="hidden w-5 h-5 mx-1 text-indigo-600 md:block" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>
  
                    <div className="text-base font-medium text-gray-500">{vars.s6_designation}</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    )
  }
  