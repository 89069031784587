// import router components
import { Route, Switch } from "react-router-dom";

// import js components
import Homepage from "./Components/Pages/Homepage";
import PricingPage from "./Components/Pages/PricingPage";
import ContactPage from "./Components/Pages/ContactPage";
import SolutionsPage from "./Components/Pages/SolutionsPage";
import ServicesPage from "./Components/Pages/ServicesPage";
import GitVersionControl from "./Components/Pages/GitVersionControl";
import SkeletonPage from "./Components/Pages/SkeletonPage";
import WhyVegaOps from "./Components/Pages/WhyVegaOps"

import * as vars from "./Variables/GitVersionControlVariables";
import * as vars2 from "./Variables/CiCdAutomationVariables";
import * as vars3 from "./Variables/DevSecOpsVariables";
import * as vars4 from "./Variables/HomepageVariables";
import * as vars5 from "./Variables/PricingVariables";
import * as vars6 from "./Variables/ManagedHosting";


function App() {
  return (
    <Switch>
      <Route exact path="/" >
        <Homepage vars={vars4} />
      </Route>

      <Route exact path="/pricing/" >
        <PricingPage vars={vars5}/>
      </Route>

      <Route exact path="/contact-us/" >
        <ContactPage />
      </Route>

      <Route exact path="/solutions">
        <SolutionsPage />
      </Route>

      <Route exact path="/solutions/gitversioncontrol">
        <SolutionsPage  vars={vars} />
      </Route>

      <Route exact path="/solutions/cicdautomation">
        <SolutionsPage vars={vars2} />
      </Route>

      <Route exact path="/solutions/devsecops">
        <SolutionsPage vars={vars3} />
      </Route>

      <Route exact path="/solutions/managed-hosting">
        <SolutionsPage vars={vars6} />
      </Route>

      <Route exact path="/skeleton/">
        <SkeletonPage />
      </Route>

      <Route exact path="/why-vegaops">
        <WhyVegaOps vars={vars6} />
      </Route>

      <Route exact path="/services">
        <ServicesPage />
      </Route>

      <Route exact path="/services/cloud-migration">
        <ServicesPage vars={vars6} />
      </Route>

    </Switch>
  );
}

export default App;
