import * as vars from "../Variables/PricingVariables";

const faqs = [
    {
        id: 1,
        question: vars.s4_sub_heading_1,
        answer: vars.s4_sub_description_1,
    },
    {
        id: 2,
        question: vars.s4_sub_heading_2,
        answer: vars.s4_sub_description_2,
    },
    {
        id: 3,
        question: vars.s4_sub_heading_3,
        answer: vars.s4_sub_description_3,
    },
    {
        id: 4,
        question: vars.s4_sub_heading_4,
        answer: vars.s4_sub_description_4,
    },
    {
        id: 5,
        question: vars.s4_sub_heading_5,
        answer: vars.s4_sub_description_5,
    },
    {
        id: 6,
        question: vars.s4_sub_heading_6,
        answer: vars.s4_sub_description_6,
    },
]

export default function FAQSection() {
    return (
        <div className="bg-white">
            <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
                <p className="mt-1 text-3xl font-medium tracking-tight text-center text-gray-900 sm:text-4xl">
            {
              vars.s4_heading
            }
          </p>
                <div className="mt-12">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                        {faqs.map((faq) => (
                            <div key={faq.id}>
                                <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
                                <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
