import * as vars from "../Variables/PricingVariables";


export default function HeaderSection(props) {

  return (
    <div className="bg-white">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">

          <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">{props.vars.heading_highlight}</h2>
          <p className="mt-1 text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
            {
              props.vars.heading
            }
          </p>
          <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
            {
              props.vars.description
            }
          </p>

        </div>
      </div>
    </div>
  )
}
