
import { CheckCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import * as vars from "../Variables/PricingVariables";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const includedFeatures = [
    vars.s2_card_description_1,
    vars.s2_card_description_2,
    vars.s2_card_description_3,
    vars.s2_card_description_4
]

export default function PricingSection() {

    const [enabled, setEnabled] = useState(false)

    return (
        <div>
        <div class="relative bg-indigo-600">
          <div aria-hidden="true" class="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block"></div>
  
          <div class="relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
              <span class="block lg:inline text-md">{vars.s1_heading}</span>
            </h1>
            <p class="mt-4 text-xl text-indigo-100">{vars.s1_description}</p>
          </div>
  
          <h2 class="sr-only">Plans</h2>
  
          <div class="relative mt-12 flex justify-center sm:mt-16">
            <div class="bg-indigo-700 p-0.5 rounded-lg flex">
              <button type="button" class="relative bg-white py-2 px-6 border-indigo-700 rounded-md shadow-sm text-sm font-medium text-indigo-700 whitespace-nowrap hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white focus:z-10">Monthly billing</button>
              <button type="button" class="ml-0.5 relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-indigo-200 whitespace-nowrap hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white focus:z-10">Yearly billing</button>
            </div>
          </div>
  
          <div class="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
            <div aria-hidden="true" class="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-indigo-700 rounded-tl-lg rounded-tr-lg lg:block"></div>
  
            <div class="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
              <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 class="text-white text-sm font-semibold uppercase tracking-wide">Starter</h3>
                  <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div class="mt-3 flex items-center">
                      <p class="text-white text-4xl font-extrabold tracking-tight">$5</p>
                      <div class="ml-4">
                        <p class="text-white text-sm">USD / mo</p>
                        <p class="text-indigo-200 text-sm">Billed yearly ($56)</p>
                      </div>
                    </div>
                    <a href="#" class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Buy Starter</a>
                  </div>
                </div>
                <h4 class="sr-only">Features</h4>
                <ul role="list" class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Basic invoicing</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Easy to use accounting</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Mutli-accounts</span>
                  </li>
                </ul>
              </div>
  
              <div class="bg-white ring-2 ring-indigo-700 shadow-md pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 class="text-indigo-600 text-sm font-semibold uppercase tracking-wide">Scale</h3>
                  <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div class="mt-3 flex items-center">
                      <p class="text-indigo-600 text-4xl font-extrabold tracking-tight">$19</p>
                      <div class="ml-4">
                        <p class="text-gray-700 text-sm">USD / mo</p>
                        <p class="text-gray-500 text-sm">Billed yearly ($220)</p>
                      </div>
                    </div>
                    <a href="#" class="bg-indigo-600 text-white hover:bg-indigo-700 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Buy Scale</a>
                  </div>
                </div>
                <h4 class="sr-only">Features</h4>
                <ul role="list" class="border-gray-200 divide-gray-200 mt-7 border-t divide-y lg:border-t-0">
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">Advanced invoicing</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">Easy to use accounting</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">Mutli-accounts</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">Tax planning toolkit</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">VAT &amp; VATMOSS filing</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-500 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-gray-600 ml-4 text-sm font-medium">Free bank transfers</span>
                  </li>
                </ul>
              </div>
  
              <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 class="text-white text-sm font-semibold uppercase tracking-wide">Growth</h3>
                  <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div class="mt-3 flex items-center">
                      <p class="text-white text-4xl font-extrabold tracking-tight">$12</p>
                      <div class="ml-4">
                        <p class="text-white text-sm">USD / mo</p>
                        <p class="text-indigo-200 text-sm">Billed yearly ($140)</p>
                      </div>
                    </div>
                    <a href="#" class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full">Buy Growth</a>
                  </div>
                </div>
                <h4 class="sr-only">Features</h4>
                <ul role="list" class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0">
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Basic invoicing</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Easy to use accounting</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Mutli-accounts</span>
                  </li>
  
                  <li class="py-3 flex items-center">
                    <svg class="text-indigo-200 w-5 h-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white ml-4 text-sm font-medium">Tax planning toolkit</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
  
        <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
          <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>
  
          <div class="mt-16 max-w-2xl mx-auto px-4 space-y-16 sm:px-6">
            <div class="border-t border-gray-200">
              <div class="border-transparent -mt-px pt-6 border-t-2 sm:w-1/2">
                <h3 class="text-gray-900 text-sm font-bold">Starter</h3>
                <p class="mt-2 text-sm text-gray-500">All your essential business finances, taken care of.</p>
              </div>
              <h4 class="mt-10 text-sm font-bold text-gray-900">Catered for business</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Tax Savings</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Easy to use accounting</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Multi-accounts</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-gray-900 text-sm font-medium">3 accounts</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Invoicing</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-gray-900 text-sm font-medium">3 invoices</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Exclusive offers</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">6 months free advisor</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Mobile and web access</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
  
              <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Instant notifications</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Budgeting tools</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Digital receipts</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Pots to separate money</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Free bank transfers</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Business debit card</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
            </div>
  
            <div class="border-t border-gray-200">
              <div class="border-indigo-600 -mt-px pt-6 border-t-2 sm:w-1/2">
                <h3 class="text-indigo-600 text-sm font-bold">Scale</h3>
                <p class="mt-2 text-sm text-gray-500">The best financial services for your thriving business.</p>
              </div>
              <h4 class="mt-10 text-sm font-bold text-gray-900">Catered for business</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-2 ring-indigo-600 shadow-md relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Tax Savings</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Easy to use accounting</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Multi-accounts</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-indigo-600 text-sm font-medium">Unlimited accounts</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Invoicing</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-indigo-600 text-sm font-medium">Unlimited invoices</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Exclusive offers</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">6 months free advisor</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Mobile and web access</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-2 ring-indigo-600 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
  
              <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-2 ring-indigo-600 shadow-md relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Instant notifications</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Budgeting tools</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Digital receipts</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Pots to separate money</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Free bank transfers</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Business debit card</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-2 ring-indigo-600 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
            </div>
  
            <div class="border-t border-gray-200">
              <div class="border-transparent -mt-px pt-6 border-t-2 sm:w-1/2">
                <h3 class="text-gray-900 text-sm font-bold">Growth</h3>
                <p class="mt-2 text-sm text-gray-500">Convenient features to take your business to the next level.</p>
              </div>
              <h4 class="mt-10 text-sm font-bold text-gray-900">Catered for business</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Tax Savings</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Easy to use accounting</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Multi-accounts</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-gray-900 text-sm font-medium">7 accounts</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Invoicing</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <span class="text-gray-900 text-sm font-medium">10 invoices</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Exclusive offers</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">6 months free advisor</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                      <dt class="pr-4 text-sm font-medium text-gray-600">Mobile and web access</dt>
                      <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
  
              <h4 class="mt-10 text-sm font-bold text-gray-900">Other perks</h4>
  
              <div class="mt-6 relative">
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
                </div>
  
                <div class="ring-1 ring-black ring-opacity-5 shadow relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
                  <dl class="divide-y divide-gray-200">
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">24/7 customer support</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Instant notifications</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Budgeting tools</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Digital receipts</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Pots to separate money</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Free bank transfers</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
  
                    <div class="py-3 flex justify-between sm:grid sm:grid-cols-2">
                      <dt class="text-sm font-medium text-gray-600 sm:pr-4">Business debit card</dt>
                      <dd class="text-center sm:px-4">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </dd>
                    </div>
                  </dl>
                </div>
  
                <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                  <div class="ring-1 ring-black ring-opacity-5 absolute right-0 w-1/2 h-full rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section aria-labelledby="comparison-heading" class="hidden lg:block">
          <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>
  
          <div class="mt-24 max-w-7xl mx-auto px-8">
            <div class="w-full border-t border-gray-200 flex items-stretch">
              <div class="-mt-px w-1/4 py-6 pr-4 flex items-end">
                <h3 class="mt-auto text-sm font-bold text-gray-900">Catered for business</h3>
              </div>
  
              <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
                <div class="border-transparent py-6 border-t-2">
                  <p class="text-gray-900 text-sm font-bold text-gray-900">Starter</p>
                  <p class="mt-2 text-sm text-gray-500">All your essential business finances, taken care of.</p>
                </div>
              </div>
  
              <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
                <div class="border-indigo-600 py-6 border-t-2">
                  <p class="text-indigo-600 text-sm font-bold text-gray-900">Scale</p>
                  <p class="mt-2 text-sm text-gray-500">The best financial services for your thriving business.</p>
                </div>
              </div>
  
              <div aria-hidden="true" class="-mt-px pl-4 w-1/4">
                <div class="border-transparent py-6 border-t-2">
                  <p class="text-gray-900 text-sm font-bold text-gray-900">Growth</p>
                  <p class="mt-2 text-sm text-gray-500">Convenient features to take your business to the next level.</p>
                </div>
              </div>
            </div>
  
            <div class="relative">
              <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full bg-white rounded-lg shadow"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="w-full h-full bg-white rounded-lg shadow"></div>
                </div>
              </div>
  
              <table class="relative w-full">
                <caption class="sr-only">
                  Business feature comparison
                </caption>
                <thead>
                  <tr class="text-left">
                    <th scope="col">
                      <span class="sr-only">Feature</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Starter plan</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Scale plan</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Growth plan</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-100">
                  <tr key="Tax Savings">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Tax Savings</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Easy to use accounting">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Easy to use accounting</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Multi-accounts">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Multi-accounts</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-gray-900 text-sm font-medium">3 accounts</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-indigo-600 text-sm font-medium">Unlimited accounts</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-gray-900 text-sm font-medium">7 accounts</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Invoicing">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Invoicing</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-gray-900 text-sm font-medium">3 invoices</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-indigo-600 text-sm font-medium">Unlimited invoices</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <span class="text-gray-900 text-sm font-medium">10 invoices</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Exclusive offers">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Exclusive offers</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="6 months free advisor">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">6 months free advisor</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Mobile and web access">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Mobile and web access</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full rounded-lg ring-2 ring-indigo-600 ring-opacity-100"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
              </div>
            </div>
  
            <h3 class="mt-10 text-sm font-bold text-gray-900">Other perks</h3>
  
            <div class="mt-6 relative">
              <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full bg-white rounded-lg shadow"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="w-full h-full bg-white rounded-lg shadow"></div>
                </div>
              </div>
  
              <table class="relative w-full">
                <caption class="sr-only">
                  Perk comparison
                </caption>
                <thead>
                  <tr class="text-left">
                    <th scope="col">
                      <span class="sr-only">Perk</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Starter plan</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Scale plan</span>
                    </th>
  
                    <th scope="col">
                      <span class="sr-only">Growth plan</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-100">
                  <tr key="24/7 customer support">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">24/7 customer support</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Instant notifications">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Instant notifications</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Budgeting tools">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Budgeting tools</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Digital receipts">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Digital receipts</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Pots to separate money">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Pots to separate money</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Free bank transfers">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Free bank transfers</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
                  </tr>
  
                  <tr key="Business debit card">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">Business debit card</th>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
  
                    <td class="px-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Yes</span>
                      </span>
                    </td>
  
                    <td class="pl-4 relative w-1/4 py-0 text-center">
                      <span class="relative w-full h-full py-3">
                        
                        <svg class="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">No</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
  
   
              <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div class="w-1/4 pr-4"></div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
                <div class="w-1/4 px-4">
                  <div class="w-full h-full rounded-lg ring-2 ring-indigo-600 ring-opacity-100"></div>
                </div>
                <div class="w-1/4 pl-4">
                  <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}
