export default function ContactHeading() {
    return (<div class="bg-blue-500">
  <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
    <div class="text-center">
      <p class="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">Get In Touch</p>
      <p class="max-w-xl mt-5 mx-auto text-xl text-white">We’ll show you how our products and services work together to automate your software development cycle.</p>
    </div>
  </div>
</div>
)
}