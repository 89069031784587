/////////////////
// SECTION 01 //
///////////////

// export const s1_title_1 = "We're hiring";
// export const s1_title_2 = 'Visit our careers page';
export const s1_heading = 'Powerful Git for';
export const s1_heading_highlight = 'Professional Teams';
export const s1_description= `Fully private enterprise Git hosting at a fraction of the cost, coupled with built-in CI/CD Automation and 24/7 Support.`;
export const s1_sub_heading = "Used by";
export const s1_img_1 = "https://tailwindui.com/img/logos/tuple-logo-gray-400.svg";
export const s1_img_1_alt = "Tuple";
export const s1_img_2 = "https://tailwindui.com/img/logos/workcation-logo-gray-400.svg";
export const s1_img_2_alt = "Workcation";
export const s1_img_3 = "https://tailwindui.com/img/logos/statickit-logo-gray-400.svg";
export const s1_img_3_alt = "StaticKit";
export const s1_heading_2 = 'Distributed is the';
export const s1_heading_highlight_2 = 'new Centralized';
export const s1_description_2 = 'Fully private enterprise Git hosting at a fraction of the cost, coupled with built-in CI/CD Automation and 24/7 Support.';
export const card_title = 'Managed, Enterprise Git Hosting';
export const s1_list_1 = 'Git Flow Design';
export const s1_list_2 = 'Code Review';
export const s1_list_3 = 'Issue Tracking';
export const s1_list_4 = 'Code Security Analysis';
export const s1_list_5 = 'Access and Workflows';
export const s1_list_6 = '24/7 DevOps Support';

/////////////////
// SECTION 02 //
///////////////

export const s2_heading = "Remote-ready Git Source Code Management";
export const s2_description = "Simple and Secure Git Hosting for all your Software Projects";
export const s2_card_1_heading = "Unlimited Projects";
export const s2_card_1_description = `Effectively manage unlimited public, internal or private projects on a central Git server dedicated to your software projects.`;
export const s2_card_2_heading = "Team Collaboration";
export const s2_card_2_description = `Implement organizational workflows, work with team members on files real-time, review, and contribute code together.`;
export const s2_card_3_heading = "Scale and Flexibility";
export const s2_card_3_description = `Efficiently handle both small-scale and large-scale projects. Record and track every activity of your team members.`;
export const s2_card_4_heading = "Code Review and Quality";
export const s2_card_4_description = `Excellent code depends on rigorous review. Design your Review workflows, improve code delivery speed and quality.`;
export const s2_card_5_heading = "Contribution Analytics";
export const s2_card_5_description = `Analyze your team's contributions over some time, identify opportunities for improvement with team members.`;
export const s2_card_6_heading = "Built-in Security";
export const s2_card_6_description = `Git is designed to maintain the integrity of your source code using cryptographic encryption algorithms.`;

/////////////////
// SECTION 03 //
///////////////

export const s3_heading = "Improve Code Quality with Code Review";
export const s3_description = `Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
viverra dui tellus ornare pharetra.`;
export const s3_heading_1 = "Improve Code Quality with Code Review";
export const s3_subheading_1 = "Improve Code Quality with Code Review";
export const s3_name_1 = "Manage Access Permissions";
export const s3_list_1 = "Design business workflows to approve code contributions by developers with Merge Requests. Control access and permissions for different stakeholders.";
export const s3_name_2 = "Security Automation";
export const s3_list_2 = "Create automated security and compliance testing policies and conduct team discussions.";
export const s3_name_3 = "Shareable Code";
export const s3_list_3 = "Share code patches or media files and provide immediate inline feedback on features.";
export const s3_heading_2 = "Deliver faster with built-in CI/CD";
export const s3_subheading_2 = "Improve Code Quality with Co";
export const s3_name_4 = "In-Built CI/CD";
export const s3_list_4 = "Built-in CI/CD integration to detect project commits and automate manual jobs like build, testing, and delivery. Set different release workflows for every Git branch.";
export const s3_name_5 = "Deployment Schedules";
export const s3_list_5 = "Schedule release deployments from Git branches to Public/Private Cloud infra of your choice.";
export const s3_name_6 = "Get Support from Expert DevOps Engineers";
export const s3_list_6 = "Our Expert DevOps Support Engineers help you architect all the CI/CD configurations as per your requirements.";
export const s3_heading_3 = "Ship Secure and Safe Applications";
export const s3_subheading_3 = "Improve Code Quality with Co";
export const s3_name_7 = "Analyze Code Quality";
export const s3_list_7 = "Built-in security analyzers to scan every code commit for Secret Keys leakages such as API keys or passwords, dependency vulnerabilities, and Code Quality.";
export const s3_name_8 = "Quick Audits";
export const s3_list_8 = "Easy auditing, compliance, and traceback of features, already shipped. Rollback Git commits in just a few clicks.";
export const s3_name_9 = "Security First";
export const s3_list_9 = "Powerful DevSecOps tool-chain to ensure vulnerabilities are captured before they are shipped to customers.";




/////////////////
// SECTION 04 //
///////////////

export const s4_heading = "Powerful Git Features";
export const s4_description = `Developed and Designed for Developers`;
export const s4_title_1 = "Activity Stream";
export const s4_description_1 = "View/Track a list of all the project activities like commits, issues, merges, comments by team members.";
export const s4_title_2 = "Commit Graphs";
export const s4_description_2 = "Analyze project's commit history and code contributions by your project members on different branches.";
export const s4_title_3 = "Rollback Commits";
export const s4_description_3 = "Easily roll back any of your commits or a single merge request on PWSLab with a click of a button.";
export const s4_title_4 = "Secure Signed commits";
export const s4_description_4 = "Identify the developer's contributions to prove that a commit was performed by a certain user using GPG signed commits.";
export const s4_title_5 = "Built-in Issue Tracking";
export const s4_description_5 = "Track project requirements and issues, assign Todos to Developers, monitor Milestone(Sprint) progress.";
export const s4_title_6 = "Project Discussions";
export const s4_description_6 = "Project members can log code commits, discuss issues, share media files, and code snippets in the issue boards.";
export const s4_title_7 = "Smart Actions";
export const s4_description_7 = "Tag/Close issues and Merge requests directly from your command-line using Git commit messages.";
export const s4_title_8 = "Built-in Continuous Delivery";
export const s4_description_8 = "Build, test, and deploy with in-built CI/CD. Benefit from customizable CI/CD configuration and fast feedback loops.";
export const s4_title_9 = "Protected Branches";
export const s4_description_9 = "Secure code contributions to sensitive production branches with merge request approvals from senior project members.";
export const s4_title_10 = "Repository Mirroring";
export const s4_description_10 = "Automatically schedule mirroring for your projects to alternative Git Hosting services for Backup or CI/CD purposes.";
export const s4_title_11 = "Import Projects";
export const s4_description_11 = "Import your projects from GitHub, BitBucket, GitLab, Gitea, Google Code, or any other VCS in just a few clicks.";
export const s4_title_12 = "Export Projects";
export const s4_description_12 = "Export your project's entire commits and related data from PWSLab to any other Git Hosting services easily.";


/////////////////
//CTA///////////
///////////////

export const heading = "Continue for FREE Forever";
export const description = "Upgrade when ready";
export const btn_name = "Call us Now!";

/////////////////
// SECTION 05 //
///////////////

export const s5_heading = "Use a single DevOps solution to manage entire Software Development Lifecycle";
export const s5_title_1 = "Mobile notifications";
export const s5_description_1 = "From Software Development till Customer Deployment and Delivery, we ensure every aspect is offered in a single interface, at a highly competitive and affordable pricing.";
export const s5_title_2 = "Reminder emails";
export const s5_description_2 = "Unlike other DIY solutions, our Expert DevOps Support Engineers will be available round the clock to assist your Tech team and fulfill your business requirements.";
export const s5_img = "https://tailwindui.com/img/features/feature-example-2.png";

/////////////////
// SECTION 06 //
///////////////

export const s6_heading_1 = "Competitive exchange rates";
export const s6_description_1 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_2 = "No hidden fees";
export const s6_description_2 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";
export const s6_heading_3 = "Transfers are instant";
export const s6_description_3 = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.";

/////////////////
// SECTION 07 //
///////////////

export const s7_heading = "Are you new to Git?";
export const s7_subheading = "No problem. We have resources to help you with migration and training for your team";
export const s7_title_1 = "Automated Migration";
export const s7_description_1 = "Migrate all your repositories from sources like GitHub, BitBucket, GitLab, Gogs, Gitea or any other Git Hosting service in just a few clicks. Using SVN? We can help with that too!";
export const s7_title_2 = "DevOps Expert Training";
export const s7_description_2 = "We will help your team level up their Git workflows, including developers, managers, system-admins, and other stakeholders. We’ll tailor a program for your team and environment.";

/////////////////
// SECTION 08 //
///////////////

export const s8_heading = "Integrations, Webhooks and API";
export const s8_subheading = "Easily integrate tools you already use to build software with 50+ integrations, Webhooks and API";
export const s8_title_1 = "Integrate Jira with PWSLab";
export const s8_description_1 = "Quickly navigate issues in PWSLab, log Git commits in a Jira issue. Trigger comments, status updates on Jira issues based on Git SCM events.";
export const s8_title_2 = "Webhooks";
export const s8_description_2 = "Webhooks can be used to update events on an external application, trigger CI jobs, update a release document, or even deploy to your production server.";
export const s8_title_3 = "Powerful API";
export const s8_description_3 = "Require an integration with your internal applications? We've got you covered with a powerful REST API, to access all our features and services.";